import UserCredentials from '../../LoginPage/Models/UserCredentials.interface';
import { updateHeader } from '../../Shared/Models/Headers/useQueryHeader';
import { updateUserState } from '../../Shared/UserContextProvider/useQueryUserState';

export async function SignIn(
  userCredentials: UserCredentials,
  loginPageUrl: string,
  changePasswordUrl: string,
  channelId: string,
  setError: (value: boolean) => void,
  setValidationMessage: (value: string) => void,
  setIsLoading: (value: boolean) => void
) {
  setIsLoading(true);
  setError(false);
  setValidationMessage('');
  const accountUrl = `/api/${userCredentials.language || 'en'}/account/`;
  const res = await fetch(`${accountUrl}SignIn`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'litium-request-context': JSON.stringify({
        channelSystemId: channelId,
      }),
    },
    body: JSON.stringify(userCredentials),
  });
  const data = await res.json();
  if (data && data.statusCode === 200) {
    if (data.message === 'Change password') {
      window.location.href = `${
        changePasswordUrl ? changePasswordUrl : data.redirectUrl
      }`;
    } else {
      window.location.href = `${
        loginPageUrl ? loginPageUrl : data.redirectUrl
      }`;
    }
  } else {
    setError(true);
    setValidationMessage(data.message);
  }
  setIsLoading(false);
}

export async function SignOut(languageRoute: string, channelId: string) {
  const accountUrl = `/api/${languageRoute || 'en'}/account/`;
  const res = await fetch(`${accountUrl}SignOut`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'litium-request-context': JSON.stringify({
        channelSystemId: channelId,
      }),
    },
  });
  const data = await res.json();

  if (data.statusCode === 200) {
    window.location.href = `${data.redirectUrl}`;
  }
}

export async function ChangePassword(
  userCredentials: UserCredentials,
  requestToken: string,
  languageRoute: string,
  channelId: string,
  setError: (value: boolean) => void,
  setValidationMessage: (value: string) => void,
  kexNavigate: (value: string) => void
) {
  const accountUrl = `/api/${languageRoute || 'en'}/account/`;
  const res = await fetch(`${accountUrl}ChangePassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'litium-request-context': JSON.stringify({
        channelSystemId: channelId,
      }),
      RequestVerificationToken: requestToken,
    },
    body: JSON.stringify(userCredentials),
  });
  const data = await res.json();

  if (data.statusCode === 200) {
    updateHeader();
    updateUserState();
  } else {
    setError(true);
    setValidationMessage(data.message);
  }

  return data;
}

export async function ChangeExpiredPassword(
  userCredentials: UserCredentials,
  changePasswordUrl: string,
  channelId: string,
  pageId: string,
  setSuccess: (value: boolean) => void,
  setError: (value: boolean) => void,
  setValidationMessage: (value: string) => void,
  setIsLoading: (value: boolean) => void
) {
  const accountUrl = `/api/${userCredentials.language || 'en'}/account/`;
  const res = await fetch(
    `${accountUrl}ChangeExpiredPassword?pageId=${pageId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'litium-request-context': JSON.stringify({
          channelSystemId: channelId,
        }),
      },
      body: JSON.stringify(userCredentials),
    }
  );
  const data = await res.json();
  if (data && data.statusCode === 200) {
    window.location.href = `${
      changePasswordUrl ? changePasswordUrl : data.redirectUrl
    }`;
  } else {
    setError(true);
    setValidationMessage(data.message);
  }
}
