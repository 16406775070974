import { useState } from 'react';
import LoginPageModel from '../../LoginPage/Models/LoginPageModel.interface';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import Input from '../../Atoms/Input/Input';
import { InputValidation } from '../../Atoms/Input/InputValidation';
import { ExclamationMarkIcon, LoadingCircle } from '../../Atoms/Icons';
import { SignIn } from './Account';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import UserCredentials from '../../LoginPage/Models/UserCredentials.interface';
import { useKexNavigate } from '../../Kex/KexRouter/KexRouter';
import KexLink from '../../Kex/KexLink';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { styled } from '../../stitches.config';
import { H1, H2 } from '../../Atoms/Typography/Headings/Heading';
import Button from '../../Atoms/Buttons/Button';
import { useUserStateData } from '../../Shared/UserContextProvider/UserContextProvider';
import UserState from '../../Shared/Models/UserState.interface';
import { useHeaderData } from '../../Organisms/Header/Header';
import CtaButton from '../../Atoms/Buttons/CtaButton';

function LoginPage() {
  const kexNavigate = useKexNavigate();
  const { pageHeading, pageIntroText, loginText, channelId } =
    useCurrentPage<LoginPageModel>();
  const [user] = useState<UserState>(useUserStateData());
  const [password, setPassword] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>('');

  const {
    'account/signIn': signIn,
    'account/email': emaiLabel,
    'account/password': passwordLabel,
    'account/forgotPassword': forgotPassword,
    'account/createAccount': createAccount,
  } = useTranslationData();

  const { languageRoute, staticPages } = useAppSettingsData();

  const userCredentials: UserCredentials = {
    userName: username,
    password: password,
    language: languageRoute,
  };

  const { dontAllowLogin } = useHeaderData();

  const disabled = password.length === 0 || username.length === 0;

  const signInUser = () => {
    if (disabled) return;
    SignIn(
      userCredentials,
      staticPages.myPage,
      staticPages.changePasswordPage,
      channelId,
      setError,
      setValidationMessage,
      setIsLoading
    );
  };

  if (dontAllowLogin) {
    kexNavigate(`/${languageRoute}/`);
  }

  if (user.authenticated) {
    kexNavigate(staticPages.myPage);
  }

  return (
    <main>
      <Container>
        <InfoGridBox>
          <StyledH1 noMargin={true}>{pageHeading}</StyledH1>
          <IntroText>{pageIntroText}</IntroText>
        </InfoGridBox>
        <FormGridBox>
          <FormContainer>
            <StyledH2 noMargin={true}>{signIn}</StyledH2>
            <FromDetailText>{loginText}</FromDetailText>
            {error && (
              <ValidationMessage>
                <ExclamationMarkIcon css={IconStyleError} />
                <ErrorMessage>{validationMessage}</ErrorMessage>
              </ValidationMessage>
            )}
            <InputValidation>
              <Input
                title={emaiLabel}
                onChange={setUsername}
                onKeyDown={(e) => e.key === 'Enter' && signInUser()}
              />
              <Input
                title={passwordLabel}
                type="password"
                onChange={setPassword}
                onKeyDown={(e) => e.key === 'Enter' && signInUser()}
              />
            </InputValidation>
            <ForgotPasswordLink href={staticPages.forgotPasswordPage}>
              {forgotPassword}
            </ForgotPasswordLink>
            <ButtonContainer>
              <SignInButton
                disabled={disabled}
                onClick={signInUser}
                type={'secondary'}
                isLoading={isLoading}
              >
                {signIn}
                {/* {isLoading ? (
                  <LoadingCircle css={StyledLoadingCircle} isLoading />
                ) : (
                  <>{signIn}</>
                )} */}
              </SignInButton>
            </ButtonContainer>
          </FormContainer>
        </FormGridBox>
      </Container>
    </main>
  );
}

export default LoginPage;

const StyledLoadingCircle = {
  color: '$white',
  h: 4,
  mx: 'auto',
  my: 'auto',
};

const IconStyleError = {
  wh: 4,
  mr: 2,
  mt: 1,
};

const Container = styled('div', {
  display: 'grid',
  gg: 4,
  gridTemplateColumns: '1fr',
  wh: '100%',
  minH: '95vh',
  justifyContent: 'center',
  grg: 0,
  mx: 'auto',
  '@mediaMinLarge': {
    gridTemplateColumns: '2fr 3.5fr',
    gg: 0,
  },
  maxW: '$contentMaxWidth',
});

const GridBox = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  p: 8,
});

const InfoGridBox = styled(GridBox, {});

const FormGridBox = styled(GridBox, {});

const StyledH1 = styled(H1, {});

const StyledH2 = styled(H2, {
  pb: 3,
});

const IntroText = styled('p', {
  fontSize: '14px',
  lineHeight: '20px',
  ls: '1px',
});

const FormContainer = styled('div', {
  fs: 13,
  fontWeight: '$fw300',
  lineHeight: '$lh15',
  ls: '$ls125',
  py: 16,
  '@mediaMinGreat': {
    px: 47,
    py: 6,
  },
});

const FromDetailText = styled('p', {
  fs: 8,
  fontWeight: '$fw400',
  lineHeight: '$lh1625',
  mb: 6,
  mt: 0,
});

const ValidationMessage = styled('div', {
  fs: 8,
  fontWeight: '$fw400',
  lineHeight: '$lh1625',
  backgroundColor: '$errorBackground',
  color: '$errorText',
  p: 6,
  my: 6,
  display: 'flex',
});

const ErrorMessage = styled('p', {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
  m: 0,
});

const ButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  mt: 8,
});

const ForgotPasswordLink = styled(KexLink, {
  fontSize: '14px',
  fontWeight: '$fw700',
  my: 'auto',
  textAlign: 'left',
  ls: '1px',
  '&:hover': {
    cursor: 'pointer',
  },
});

const SignInButton = styled(CtaButton, {});
